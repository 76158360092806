// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
import Rails from '@rails/ujs'

import { dateToInputString } from '@experiments-labs/rise_ui/src/lib/Dates'

// import Turbolinks from 'turbolinks'
// import ActiveStorage from '@rails/activestorage'
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')
//
// Turbolinks.start()
// ActiveStorage.start()

import '@/helpers/markdown.js'
import '../stylesheets/style.scss'

// Start UJS
Rails.start()

// Default map projection for OpenLayers
window.MAPS_PROJECTION = 'EPSG:3857'

window.dateToInputString = dateToInputString
